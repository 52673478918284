import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { currentDate } from '@istvan-kreisz/hnsw-library'
import { useCallback } from 'react'

export const useFingerprintAndIP = () => {
	const updateFpIfNeeded = useCallback(async () => {
		const getFingerPrint = async () => {
			const fp = await FingerprintJS.load()

			const result = await fp.get()
			return result.visitorId
		}

		const savedFingerprint = localStorage.getItem('_p_f_')
		if (!savedFingerprint) {
			const newFingerPrint = await getFingerPrint()
			localStorage.setItem('_p_f_', newFingerPrint)
		}
	}, [])

	const setIPIfNeeded = useCallback(async () => {
		const savedIP = localStorage.getItem('_i_')
		const ipFetched = localStorage.getItem('_if_')

		if (savedIP || ipFetched) return

		try {
			localStorage.setItem('_if_', `${currentDate()}`)
			const controller = new AbortController()
			const timeoutId = setTimeout(() => controller.abort(), 1500)

			const { ip, country_code2 } = await (
				await fetch(
					`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.NEXT_PUBLIC_IPGEOLOCATION_API_KEY}`,
					{ signal: controller.signal }
				)
			).json()
			clearTimeout(timeoutId)

			localStorage.setItem('_i_', ip)
			localStorage.setItem('_c_', country_code2)
		} catch {}
	}, [])

	return { updateFpIfNeeded, setIPIfNeeded }
}
